import React from "react";
import { Box, VStack, Text, HStack, Divider, Center } from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";
import MediaItem from "./MediaItem"; // Importar el componente MediaItem

function RightBar({ mediaResources, mediaEndRef, isDarkMode }) {
  const renderResources = (mediaResource, idx) => {
    console.log("rigthbar", mediaResource);
    return mediaResource.urls.map((file, fileIdx) => (
      <MediaItem
        key={idx + fileIdx}
        fileName={file.file_name}
        fileUrl={file.url}
        fileType={mediaResource.media_type}
      />
    ));
  };

  return (
    <Box
      width="40%"
      borderRadius={30}
      p={4}
      bg={isDarkMode ? "#FBFBFB" : "#27272D"}
      overflowY="auto"
      pt={5}
      pb={2}
      marginLeft={10}
      marginRight={10}
      marginTop={20}
    >
      <VStack align="stretch" height="100%">
        <Box width="100%" paddingLeft={1} paddingRight={1}>
          <HStack justify="space-between">
            <VStack>
              <Text
                fontSize="18px"
                fontWeight="400"
                margin={0}
                pl={5}
                pr={5}
                pt={5}
                pb={2}
              >
                Documentos Generados
              </Text>
              <Divider
                align="center"
                borderColor="#707070"
                marginLeft={10}
                mb={6}
              />
            </VStack>
            <IoMdClose size={20} />
          </HStack>
        </Box>

        {mediaResources.length === 0 ? (
          <Center>
            <Text align="center" marginTop="50%" pl={5} pr={5}>
              Tus documentos generados aparecerán aquí. Solicita un manual o
              cualquier otro documento y lo verás en esta sección.
            </Text>
          </Center>
        ) : (
          mediaResources.map((media, idx) => renderResources(media, idx))
        )}
        <div ref={mediaEndRef} />
      </VStack>
    </Box>
  );
}

export default RightBar;
