import React, { useState } from "react";
import { Input, Button, HStack, Box } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { FaArrowUp } from "react-icons/fa";

const InputUser = ({
  handleSendMessage,
  readyState,
  inputMessage,
  setInputMessage,
  isDarkMode,
  companyName,
}) => {
  return (
    <Box>
      <HStack>
        <Input
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          variant="filled"
          p={4}
          borderRadius="35px"
          placeholder="Escribe tu mensaje..."
          border={
            companyName === "CHEVRON"
              ? "2px solid #53B2DC"
              : "2px solid #F78653"
          }
        />
        <Button
          bg={
            companyName
              ? "linear-gradient(90deg, #06549B, #2FA1D3)"
              : "linear-gradient(180deg, #F78653 0%, #8B422E 100%)"
          }
          onClick={handleSendMessage}
          disabled={readyState !== readyState.OPEN}
          borderRadius="full"
          p={4}
        >
          <FaArrowUp color={isDarkMode ? "white" : "white"} />
        </Button>
      </HStack>
    </Box>
  );
};

export default InputUser;
