import React, { createContext, useState, useContext } from "react";

// Crear el contexto
const CompanyContext = createContext();

// Proveedor del contexto
export const CompanyProvider = ({ children }) => {
  //change this var to CHEVRON to use blue theme
  const [companyName, setCompanyName] = useState("CHEVRON1");

  // Función para alternar el tema
  const changeCompanyName = (companyName) => {
    setCompanyName(companyName);
  };

  return (
    <CompanyContext.Provider value={{ companyName, changeCompanyName }}>
      {children}
    </CompanyContext.Provider>
  );
};

// Hook para usar el contexto en los componentes
export const useCompany = () => useContext(CompanyContext);
