import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const accessToken = localStorage.getItem("accessToken");
  console.log("Access Token in PrivateRoute:", accessToken);
  return accessToken ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
