import React from "react";
import {
  Box,
  VStack,
  Text,
  Button,
  Divider,
  Image,
  HStack,
  Center,
} from "@chakra-ui/react";
import { FaPen } from "react-icons/fa";
import ThemeToggle from "./ThemeToggle";
import "./barchevron.css";
import { useBreakpointValue } from "@chakra-ui/react";
import { RiRobot3Line } from "react-icons/ri";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { LuPlus } from "react-icons/lu";

const historyItems = [
  "¿Cuáles son los flujos...?",
  "¿Cuál es la capacidad...?",
  "¿Cuál es el coeficiente...?",
];

const assistants = ["Operaciones", "Mantenimiento", "Procesos"];

function LeftBar() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Renderiza el LeftBar solo si no es móvil
  if (isMobile) {
    return null;
  }
  return (
    <Box display="flex" height="100vh" width="24%">
      {/* Barra Naranja */}
      <Box className="leftbar-box" />

      {/* Contenedor Principal */}
      <Box className="leftbar-container" width="95%">
        <VStack className="leftbar-vstack">
          {/* Logo Section */}
          <Box className="leftbar-logo">
            <Image src="/assets/images/auramining.png" alt="Company Logo" />
          </Box>

          {/* New Consultation Button */}
          <HStack
            className="leftbar-new-query"
            justifyContent="center"
            alignItems="center"
          >
            <LuPlus p={0} size={20} />
            <Text
              verticalAlign="middle"
              alignSelf="center"
              textAlign="center"
              color="white"
              pt="11%"
            >
              Nueva consulta
            </Text>
          </HStack>

          {/* Assistants Section */}
          <Box paddingLeft={5} alignContent="flex-start" alignSelf="flex-start">
            <Text color="white">Asistentes</Text>
            <VStack>
              {assistants.map((assistant, index) => (
                <Button bg="transparent" key={index} w="100%">
                  <HStack spacing="20px" align="center" w="100%">
                    <RiRobot3Line color="white" />

                    <Text
                      paddingTop="18%"
                      color="white"
                      flex="1"
                      textAlign="left"
                      align="center"
                    >
                      {assistant}
                    </Text>
                  </HStack>
                </Button>
              ))}
            </VStack>
          </Box>

          {/* Divider */}
          <Divider className="leftbar-divider" />

          {/* Assistants Section */}
          <Box paddingLeft={5} alignContent="flex-start" alignSelf="flex-start">
            <Text color="white">Historial</Text>
            <VStack>
              {historyItems.map((assistant, index) => (
                <Button bg="transparent" key={index} w="100%">
                  <HStack spacing="20px" align="center" w="100%">
                    <BiMessageRoundedDetail color="white" />

                    <Text
                      paddingTop="18%"
                      color="white"
                      flex="1"
                      textAlign="left"
                      overflow="hidden"
                      align="center"
                    >
                      {assistant}
                    </Text>
                  </HStack>
                </Button>
              ))}
            </VStack>
          </Box>

          {/* Push the following content to the bottom */}
          <Box flexGrow={1}></Box>

          {/* Bottom Logo Section and Theme Toggle */}
          <VStack className="leftbar-theme-toggle">
            <Image
              src="/assets/images/logo_leftbar.png"
              className="leftbar-footer-logo"
            />
            <ThemeToggle />
          </VStack>
        </VStack>
      </Box>
    </Box>
  );
}

export default LeftBar;
