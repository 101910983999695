import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useTheme } from "../providers/ThemeContext";

const AssistantMessage = (
  { currentMessage, isTyping, companyName } // Cambiado a destructuración de props
) => {
  const { isDarkMode } = useTheme();

  return (
    <>
      <Flex align="flex-start">
        <Box
          border={
            companyName === "CHEVRON"
              ? "1px solid #53B2DC"
              : "1px solid #F78653"
          }
          borderRadius="50px"
          p={2}
          display="inline-block"
          mt={8}
        >
          <Image
            src="/assets/images/assistant-photo.png"
            width="30px"
            height="30px"
          />
        </Box>
        <Box p={3} maxW="70%" mb={1} mt={2} ml={3}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              p: ({ node, ...props }) => (
                <Text
                  mt={5}
                  {...props}
                  color={isDarkMode ? "black" : "white"}
                />
              ),
              li: ({ node, ...props }) => (
                <Box
                  as="li"
                  color={isDarkMode ? "black" : "white"}
                  mt={2}
                  ml={10}
                  pb={0}
                  {...props}
                />
              ),
              h1: ({ node, ...props }) => (
                <Text
                  as="h1"
                  mt={6}
                  fontSize="2xl"
                  color={isDarkMode ? "black" : "white"}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Text
                  as="h2"
                  mt={6}
                  color={isDarkMode ? "black" : "white"}
                  fontSize="xl"
                  {...props}
                />
              ),
              h3: ({ node, ...props }) => (
                <Text
                  as="h3"
                  mt={6}
                  color={isDarkMode ? "black" : "white"}
                  fontSize="lg"
                  {...props}
                />
              ),
              blockquote: ({ node, ...props }) => (
                <Box
                  as="blockquote"
                  pl={4}
                  borderLeft="4px solid #F78653"
                  mt={4}
                  color={isDarkMode ? "black" : "white"}
                  {...props}
                />
              ),
              table: ({ node, ...props }) => (
                <Table
                  variant="simple"
                  mt={4}
                  mb={4}
                  color={!isDarkMode ? "black" : "white"}
                  {...props}
                />
              ),
              thead: ({ node, ...props }) => (
                <Thead color={!isDarkMode ? "black" : "white"} {...props} />
              ),
              tbody: ({ node, ...props }) => (
                <Tbody color={!isDarkMode ? "black" : "white"} {...props} />
              ),
              tr: ({ node, ...props }) => (
                <Tr color={!isDarkMode ? "black" : "white"} {...props} />
              ),
              th: ({ node, ...props }) => (
                <Th
                  borderColor={!isDarkMode ? "gray.600" : "gray.200"}
                  color={!isDarkMode ? "white" : "gray.600"}
                  {...props}
                />
              ),
              td: ({ node, ...props }) => (
                <Td
                  borderColor={!isDarkMode ? "gray.600" : "gray.200"}
                  color={!isDarkMode ? "white" : "black"}
                  {...props}
                />
              ),
            }}
          >
            {currentMessage}
          </ReactMarkdown>
          {/* {!isTyping && <Feedback />} */}
        </Box>
      </Flex>
    </>
  );
};

export default AssistantMessage;
