// MainContent.js
import React, { useState, useCallback, useEffect, useRef } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Box, VStack, HStack, Text, Divider, Flex } from "@chakra-ui/react";

// Import components
import SuggestionsComponents from "../components/SuggestionsComponents";
import Loading from "../components/Loading";
import AssistantMessage from "../components/AssistantMessage";
import UserMessage from "../components/UserMessage";
import RightBar from "../components/RightBar";
import InputUser from "../components/InputUser";
import Notification from "../components/Notification";
import FileCard from "../components/FileCard";
import ImageCard from "../components/ImageCard";
import Feedback from "../components/Feeedback";
import { useBreakpointValue } from "@chakra-ui/react";
import SuggestionsComponentChevron from "../components/SuggestionsComponentChevron";

function MainContent({ isDarkMode, companyName }) {
  // State declarations
  const [sessionId, setSessionId] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [currentMessage, setCurrentMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mediaResources, setMediaResources] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [temporaryDocuments, setTemporaryDocuments] = useState([]);

  // Refs
  const messagesEndRef = useRef(null);
  const mediaEndRef = useRef(null);

  // Environment variables
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendWebsocketUrl = process.env.REACT_APP_BACKEND_WEBSOCKET_URL;

  // WebSocket setup
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    sessionId ? `${backendWebsocketUrl}/ws/chat/${sessionId}` : null
  );

  const isMobile = useBreakpointValue({ base: true, md: false });

  // Effects
  useEffect(() => {
    fetchSessionId();
  }, []);

  useEffect(() => {
    handleLastMessage();
  }, [lastMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messageHistory, currentMessage, isLoading]);

  useEffect(() => {
    scrollToMediaBottom();
  }, [mediaResources]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNotification(true);
    }, 30000);
    return () => clearTimeout(timer);
  }, []);

  // Helper functions
  const fetchSessionId = () => {
    fetch(`${backendUrl}/new-session`)
      .then((response) => response.json())
      .then((data) => setSessionId(data.session_id));
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToMediaBottom = () => {
    mediaEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const handleLastMessage = () => {
    if (lastMessage === null) return;

    setIsLoading(false);

    try {
      const parsedMessage = JSON.parse(lastMessage.data);
      handleParsedMessage(parsedMessage);
    } catch (error) {
      if (
        lastMessage.data &&
        lastMessage.data.end &&
        lastMessage.data.end === "end"
      ) {
        finishAssistantMessage();
      }
    }
  };

  const handleParsedMessage = (parsedMessage) => {
    console.log("parsedMessage", parsedMessage);
    if (parsedMessage["media-content"]) {
      updateMediaResources(parsedMessage["media-content"]);
    }

    // if (parsedMessage.message) {
    //   addAssistantMessage(parsedMessage.message);
    // }

    if (parsedMessage.context) {
      setTemporaryDocuments(parsedMessage.context);
    }

    if (parsedMessage.response && parsedMessage.response !== "") {
      console.log("media response is ", parsedMessage.response);
      updateCurrentMessage(parsedMessage.response);
      // addImagesMessage(parsedMessage.images);
    }

    if (parsedMessage.end && parsedMessage.end === "end") {
      finishAssistantMessage();
    }

    // if (parsedMessage.images && parsedMessage.images.length) {
    //   if (parsedMessage.response && parsedMessage.response !== "") {
    //     addAssistantMessage(parsedMessage.response);
    //   }

    //   addImagesMessage([parsedMessage.images]);
    // }

    // if (parsedMessage.documents && parsedMessage.documents.length) {
    //   if (parsedMessage.response && parsedMessage.response !== "") {
    //     updateMediaResources([
    //       {
    //         urls: [parsedMessage.documents],
    //         media_type: "document",
    //         message: "",
    //       },
    //     ]);
    //   }
    // }

    // addImagesMessage(parsedMessage.images);

    if (parsedMessage.urls && parsedMessage.urls.length) {
      if (parsedMessage.message && parsedMessage.message !== "") {
        addAssistantMessage(parsedMessage.message);
      }

      console.log("parsedMessage", parsedMessage);
      if (parsedMessage.media_type == "image") {
        const imageUrls = parsedMessage.urls.map((item) => item.url);

        addImagesMessage(imageUrls);
      }

      if (
        parsedMessage.media_type &&
        parsedMessage.urls &&
        parsedMessage.urls.length
      ) {
        updateMediaResources([
          {
            urls: parsedMessage.urls,
            media_type: parsedMessage.media_type,
            message: "",
          },
        ]);
      }

      let documents = [];

      parsedMessage.urls.map((item) => {
        if (item.type == "document") {
          documents.push({ url: item.url, file_name: item.file_name });
        }
        console.log("item", item);
        if (item.type == "image") addImagesMessage([item.url]);
      });

      if (documents && documents.length) {
        updateMediaResources([
          {
            urls: documents,
            media_type: "document",
            message: "",
          },
        ]);

        documents = [];
      }

      // addImagesMessage(parsedMessage.urls);
    } else {
      if (parsedMessage.message && parsedMessage.message !== "") {
        addAssistantMessage(parsedMessage.message);
      }
    }
  };

  const updateMediaResources = (mediaContent) => {
    setMediaResources((prev) => [...prev, ...mediaContent]);
  };

  const addAssistantMessage = (message) => {
    if (message && message !== "") {
      setMessageHistory((prev) => [
        ...prev,
        { type: "assistant", text: message },
      ]);
    }
    setCurrentMessage("");
  };

  const addDocumentMessage = (context) => {
    setMessageHistory((prev) => [
      ...prev,
      {
        type: "document",
        document: context,
      },
    ]);
    setCurrentMessage("");
  };

  const addImagesMessage = (context) => {
    setMessageHistory((prev) => [
      ...prev,
      {
        type: "image",
        images: context,
      },
    ]);
    setCurrentMessage("");
  };

  const updateCurrentMessage = (response) => {
    setCurrentMessage((prev) => prev + response);
  };

  const finishAssistantMessage = () => {
    if (currentMessage && currentMessage !== "") {
      setMessageHistory((prev) => [
        ...prev,
        { type: "assistant", text: currentMessage },
      ]);
    }
    if (temporaryDocuments && temporaryDocuments.length) {
      addDocumentMessage(temporaryDocuments);
      setTemporaryDocuments([]);
      scrollToBottom();
    }
    setCurrentMessage("");
    scrollToBottom();
  };

  // Event handlers
  const handleSendMessage = useCallback(() => {
    sendMessage(inputMessage);
    setMessageHistory((prev) => [
      ...prev,
      { type: "user", text: inputMessage },
    ]);
    setInputMessage("");
    setIsLoading(true);
  }, [sendMessage, inputMessage]);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleNotificationClick = () => {
    const message = "Necesito información de la alerta";
    sendMessage(message);
    setMessageHistory((prev) => [
      ...prev,
      { type: "user", text: message, fromNotification: true },
    ]);
    setInputMessage("");
    setShowNotification(false);
  };

  const renderHistoryMessage = (message, idx) => {
    if (message.type === "assistant") {
      return (
        <AssistantMessage
          currentMessage={message.text}
          isLoading={isLoading}
          companyName={companyName}
        />
      );
    } else if (message.type === "image") {
      return <ImageCard images={message.images} />;
    } else if (message.type === "user") {
      return <UserMessage message={message} />;
    } else if (message.type === "document" && message.document.length) {
      return (
        <FileCard
          documents={message.document}
          isDarkMode={isDarkMode}
          companyName={companyName}
        ></FileCard>
      );
    }
  };

  const getColor = (classname) => {
    if (companyName === "CHEVRON") {
      if (classname === "bg") {
        return isDarkMode ? "white" : "white";
      }
    } else {
      if (classname === "bg") {
        return isDarkMode ? "white" : "#242427";
      }
    }
  };

  // Render
  return (
    <HStack
      bg={getColor("bg")}
      height="100%"
      spacing={0}
      alignItems="stretch"
      width="100%"
    >
      <Box width="100%" p={4}>
        <VStack spacing={4} align="stretch" height="100%">
          <Box
            flex="1"
            overflowY="auto"
            bg={isDarkMode ? "white" : "#242427"}
            p={4}
          >
            {messageHistory.length === 0 && !currentMessage && !isLoading ? (
              companyName === "CHEVRON" ? (
                <SuggestionsComponentChevron isDarkMode={isDarkMode} />
              ) : (
                <SuggestionsComponents isDarkMode={isDarkMode} />
              )
            ) : (
              <>
                {console.log("messageHistory", messageHistory)}
                {messageHistory.map((message, idx) => (
                  <React.Fragment
                    key={idx}
                    align={
                      message.type === "user" ? "flex-start" : "flex-start"
                    }
                    justifyContent={
                      message.type === "user" ? "flex-end" : "flex-start"
                    }
                  >
                    {renderHistoryMessage(message, idx)}
                  </React.Fragment>
                ))}
                {/* <Feedback /> */}
                <Divider borderColor="#707070" my={4} />

                {currentMessage && (
                  <AssistantMessage
                    currentMessage={currentMessage}
                    isLoading={isLoading}
                    companyName={companyName}
                  />
                )}

                {isLoading && <Loading />}

                <div ref={messagesEndRef} />
              </>
            )}
          </Box>

          <InputUser
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleSendMessage={handleSendMessage}
            readyState={readyState}
            isDarkMode={isDarkMode}
            companyName={companyName}
          />
        </VStack>
      </Box>

      {!isMobile && (
        <RightBar mediaResources={mediaResources} isDarkMode={isDarkMode} />
      )}

      {/* <RightBar mediaResources={mediaResources} isDarkMode={isDarkMode} /> */}
      <Notification
        showNotification={showNotification}
        handleCloseNotification={handleCloseNotification}
        handleNotificationClick={handleNotificationClick}
      />
    </HStack>
  );
}

export default MainContent;
