import React from "react";
import {
  Box,
  Text,
  Flex,
  Icon,
  useBreakpointValue,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { FaArrowRight, FaRegFile } from "react-icons/fa";

const FileCard = ({ documents, isDarkMode, companyName }) => {
  const boxWidth = useBreakpointValue({ base: "100%", sm: "90%", md: "478px" });
  const boxHeight = useBreakpointValue({
    base: "auto",
    sm: "auto",
    md: "57px",
  });
  const fontSize = useBreakpointValue({ base: "12px", sm: "13px", md: "15px" });
  const iconSize = useBreakpointValue({ base: 3, sm: 4, md: 5 });
  const padding = useBreakpointValue({ base: 2, sm: 3, md: 4 });

  return (
    <VStack align="flex-start" pb={5}>
      <Text marginLeft="7.5%" fontWeight="bold" fontSize={18}>
        Referencias:{" "}
      </Text>
      {documents.map((document, index) => (
        <Box
          key={index}
          width={boxWidth}
          minHeight={boxHeight}
          bg={isDarkMode ? "white" : "#242427"}
          borderRadius="5px"
          // border="1px solid"
          borderColor={"#964F3C"}
          position="relative"
          maxWidth="100%"
          marginLeft="7.5%"
          marginTop={0}
          marginBottom={5}
          justifyItems={{ base: "center", sm: "center" }}
          border={
            companyName === "CHEVRON"
              ? "1px solid #53B2DC"
              : "1px solid #F78653"
          }
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            minHeight={boxHeight}
            px={padding}
            py={2}
            flexWrap={{ base: "wrap", sm: "nowrap" }}
          >
            <FaRegFile
              color={companyName === "CHEVRON" ? "#53B2DC" : "#964F3C"}
            />

            <Tooltip
              label={document.location}
              aria-label="Nombre del archivo"
              placement="top"
            >
              <Text
                fontFamily="Lato, sans-serif"
                fontSize={fontSize}
                lineHeight="1.2"
                isTruncated
                flexGrow={1}
                textAlign={{ base: "center", sm: "left" }}
                my={{ base: 2, sm: 0 }}
                pl={5}
                pr={5}
              >
                {document.location}
              </Text>
            </Tooltip>
            <FaArrowRight
              color={companyName === "CHEVRON" ? "#53B2DC" : "#964F3C"}
            />
          </Flex>
        </Box>
      ))}
    </VStack>
  );
};

export default FileCard;
