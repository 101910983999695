import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");

    if (code) {
      // Intercambia el código por tokens en el backend
      axios
        .post("http://localhost:8000/auth/token", { code })
        .then((response) => {
          const { id_token, access_token, refresh_token } = response.data;
          // Almacenar los tokens en el local storage
          localStorage.setItem("idToken", id_token);
          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);
          // Redirigir a la ruta protegida
          navigate("/products/aura");
        })
        .catch((error) => {
          console.error("Error exchanging code for token:", error);
          // Redirigir al login en caso de error
          navigate("/login");
        });
    } else {
      // Si no hay código, redirigir al login
      navigate("/login");
    }
  }, [navigate]);

  return <div>Loading...</div>;
}

export default Callback;
