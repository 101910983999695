import React from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  CloseButton,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import { GoAlertFill } from "react-icons/go";

const Notification = ({
  showNotification,
  handleNotificationClick,
  handleCloseNotification,
}) => {
  // Usar `useBreakpointValue` para cambiar la posición y el tamaño según el tamaño de la pantalla
  const positionStyle = useBreakpointValue({
    base: { top: "10px", bottom: "unset" }, // Para móviles
    md: { bottom: "20px", top: "unset" }, // Para pantallas más grandes
  });

  const boxWidth = useBreakpointValue({
    base: "150px", // Ancho más pequeño en móviles
    md: "300px", // Ancho estándar en pantallas más grandes
  });

  const boxHeight = useBreakpointValue({
    base: "50px", // Ancho más pequeño en móviles
    md: "100px", // Ancho estándar en pantallas más grandes
  });

  const fontSize = useBreakpointValue({
    base: "10px", // Tamaño de fuente más pequeño en móviles
    md: "16px", // Tamaño de fuente en pantallas más grandes
  });

  const iconSize = useBreakpointValue({
    base: "30px", // Tamaño del ícono más pequeño en móviles
    md: "50px", // Tamaño estándar en pantallas más grandes
  });

  return (
    <Box
      position="fixed"
      right="20px"
      bg="#E75050"
      color="white"
      borderColor="#F97C7C"
      borderWidth="2px"
      p={3} // Padding ajustado
      borderRadius="md"
      boxShadow="md"
      zIndex={1000}
      {...positionStyle} // Estilo de posición dinámico
      transform={showNotification ? "translateX(0)" : "translateX(100%)"}
      transition="transform 0.5s ease"
      onClick={handleNotificationClick}
      cursor="pointer"
    >
      <HStack alignItems="center" alignContent="center" justify="center">
        <Box display="flex" alignItems="center" w="40px" h={boxHeight}>
          <Center p={0} m={0} justify="center">
            <GoAlertFill color="#FFFFFF" size={iconSize} />
          </Center>
        </Box>
        <Box w={boxWidth} h={boxHeight} align="center">
          <Center>
            <VStack w="100%" h={boxHeight} align="center">
              <Text
                textAlign="center"
                p={0}
                m={0}
                fontSize={fontSize}
                fontWeight={600}
                color="white"
              >
                Aura Alert #PAB001:
              </Text>
              <Text
                p={0}
                m={0}
                textAlign="center"
                fontSize={fontSize}
                color="white"
              >
                high_crusher_level está por encima del límite permitido
              </Text>
            </VStack>
          </Center>
        </Box>
      </HStack>
      <CloseButton
        position="absolute"
        top="4px"
        right="4px"
        onClick={handleCloseNotification}
        size="sm"
      />
    </Box>
  );
};

export default Notification;
