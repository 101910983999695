import React from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Image,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";

import { TfiReload } from "react-icons/tfi";
import { GoGear } from "react-icons/go";
import { IoDocumentOutline } from "react-icons/io5";

const SuggestionsComponentChevron = ({ isDarkMode }) => {
  const userName = "Vicente"; // Puedes hacer que este nombre sea dinámico si es necesario

  // Usar useBreakpointValue para cambiar el layout a "column" en pantallas móviles
  const stackDirection = useBreakpointValue({ base: "column", md: "row" });
  const boxWidth = useBreakpointValue({ base: "70%", md: "30%" });

  return (
    <Box textAlign="center" paddingLeft="5%" paddingRight="5%" paddingTop="5%">
      <VStack>
        <VStack>
          <Text
            as="span"
            fontSize="30px"
            fontWeight={600}
            sx={{
              background: isDarkMode
                ? "linear-gradient(90deg, #06549B, #2FA1D3)"
                : "linear-gradient(90deg, white, #F78653)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
            padding={0}
            margin={0}
          >
            Hola {userName}
          </Text>
          <Text
            as="span"
            fontSize="30px"
            fontWeight={600}
            sx={{
              background: isDarkMode
                ? "linear-gradient(90deg, #06549B, #2FA1D3)"
                : "linear-gradient(90deg, white, #F78653)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            ¿En qué puedo ayudarte hoy?
          </Text>
        </VStack>

        <Box maxWidth="60%">
          <Text fontSize="md" align="center" mt={3}>
            Utiliza una de las sugerencias más comunes a continuación o escribe
            tu propia consulta para comenzar.
          </Text>
        </Box>

        <Text textAlign="center">Sugerencias:</Text>

        {/* Cambiar a Stack para poder controlar la dirección responsiva */}
        <Stack direction={stackDirection} spacing={4} mt={0} align="center">
          <Box
            borderWidth={1}
            borderColor={isDarkMode ? "#000000" : "white"}
            padding={4}
            width={boxWidth} // Ajustar el ancho según el tamaño de pantalla
          >
            <VStack align="center" spacing={6}>
              {/* <Image src="/assets/images/reload.png" paddingBottom={4}></Image> */}
              <TfiReload size="40" color="#0D92D0" />

              <Box maxWidth="100%">
                <Text textAlign="center">
                  Consultar el estado de los equipos en tiempo real
                </Text>
              </Box>
            </VStack>
          </Box>

          <Box
            borderWidth={1}
            borderColor={isDarkMode ? "#000000" : "white"}
            padding={4}
            width={boxWidth} // Ajustar el ancho según el tamaño de pantalla
          >
            <VStack align="center" spacing={6}>
              {/* <Image
                src="/assets/images/rodamiento.png"
                paddingBottom={4}
              ></Image> */}
              <GoGear size="40" color="#0D92D0" />

              <Box maxWidth="100%">
                <Text textAlign="center">
                  Solicitar historial de paradas no planificadas
                </Text>
              </Box>
            </VStack>
          </Box>

          <Box
            borderWidth={1}
            borderColor={isDarkMode ? "#000000" : "white"}
            padding={4}
            width={boxWidth} // Ajustar el ancho según el tamaño de pantalla
          >
            <VStack align="center" spacing={6}>
              {/* <Image src="/assets/images/paper.png" paddingBottom={4}></Image> */}
              <IoDocumentOutline size="40" color="#0D92D0" />
              <Box maxWidth="100%">
                <Text textAlign="center">
                  Solicitar información de manuales técnicos
                </Text>
              </Box>
            </VStack>
          </Box>
        </Stack>
      </VStack>
    </Box>
  );
};

export default SuggestionsComponentChevron;
