import React, { useEffect } from "react";
import { Box, Flex, Text, Center, HStack, Button } from "@chakra-ui/react";
import { useTheme } from "../providers/ThemeContext";

const UserMessage = (
  { message } // Cambiado a destruct
) => {
  useEffect(() => {
    console.log("message", message);
  }, [message]);

  const { isDarkMode } = useTheme();

  return (
    <Flex
      bg={isDarkMode ? "#F1F3F6" : "#27272D"}
      p={3}
      textAlign={"right"}
      position="relative"
      align={"flex-start"}
      justifyContent="flex-end" // Agregado para alinear a la derecha
      borderRadius="md" // Agregado para hacer una burbuja de chat
      width="100%" // Agregado para hacer el contenedor más pequeño
      height="8%"
    >
      <Center>
        <HStack alignItems="center" justifyItems="center" alignContent="center">
          {message.fromNotification && (
            <Button
              // bg="#FFD700"
              bg="yellow.400"
              color="black"
              pt={1}
              mb={5}
              mr={3}
              height={10}
              borderRadius="md"
              fontSize="sm"
              fontWeight="bold"
            >
              #PAB001
            </Button>
          )}
          <Text alignItems="center" pt={2}>
            {message.text}
          </Text>
        </HStack>
      </Center>
    </Flex>
  );
};

export default UserMessage;
