import React from "react";
import { HStack } from "@chakra-ui/react";
import MainContent from "./MainContent";
import LeftBar from "../components/LeftBar";
import LeftBarChevron from "../components/LeftBarChevron";

const Principal = ({ companyName, isDarkMode }) => {
  return (
    <HStack spacing={0} height="100vh" alignItems="stretch">
      {console.log("company name  ", companyName)}

      {/* {companyName === "CHEVRON" ? <LeftBarChevron /> : <LeftBar />} */}
      <LeftBar />
      <MainContent isDarkMode={isDarkMode} companyName={companyName} />
    </HStack>
  );
};

export default Principal;
