import React from "react";
import {
  Box,
  VStack,
  Text,
  HStack,
  Link,
  IconButton,
  Divider,
  Center,
  Flex,
  Square,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { MdInsertDriveFile } from "react-icons/md";
import { FaEye, FaDownload, FaShare } from "react-icons/fa";
import { FaRegFileImage } from "react-icons/fa";
import { useTheme } from "../providers/ThemeContext";

function MediaItem({ fileName, fileUrl, fileType }) {
  const { isDarkMode } = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="90%"
      height="20%"
      borderRadius="10px"
      border="1px solid #E3E1E1"
      pt={2}
      pb={2}
      mb={4}
      ml={4}
      bg={isDarkMode ? "#FBFBFB" : "#242427"}
      boxShadow="md"
      overflow="auto" // Scroll automático si los elementos desborda
      wrap="wrap" // Hace que los elementos se ajusten al tamaño del contenedor
    >
      <HStack wrap="wrap" spacing={5} align="center">
        {/* <MdInsertDriveFile size={40} color="#3D3D3D" /> */}
        <VStack>
          <HStack
            spacing={2}
            alignItems="center"
            display="flex"
            pb={-10}
            pt={0}
          >
            <FaRegFileImage
              color={isDarkMode ? "black" : "white"}
              size="24px"
            />

            <Text pt="2" pb="0" pl="10" fontSize="14px" overflow="hidden">
              {fileName}
            </Text>

            <Center w="120px" h="30px"></Center>
          </HStack>
          <Divider borderColor="#E3E1E1" width="100%" />
          <HStack spacing={6}>
            <Link href={fileUrl} isExternal color="black">
              <HStack spacing={2}>
                <FaEye color={isDarkMode ? "black" : "white"} />
                <Text fontSize="14px" pt={6}>
                  Ver
                </Text>
              </HStack>
            </Link>
            <Link href={fileUrl} isExternal download color="black">
              <HStack spacing={2}>
                <FaDownload color={isDarkMode ? "black" : "white"} />
                <Text fontSize="14px" pt={6}>
                  Descargar
                </Text>
              </HStack>
            </Link>
            <Link href={fileUrl} isExternal color="black">
              <HStack spacing={2}>
                <FaShare color={isDarkMode ? "black" : "white"} />
                <Text fontSize="14px" pt={6}>
                  Compartir
                </Text>
              </HStack>
            </Link>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
}

export default MediaItem;
