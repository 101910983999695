import React, { useState } from "react";
import { Box, Text, Flex, useColorModeValue } from "@chakra-ui/react";
import { FaSun, FaMoon } from "react-icons/fa";
import { useTheme } from "../providers/ThemeContext";
import { useCompany } from "../providers/CompanyContext";

function ThemeToggle() {
  const { isDarkMode, toggleTheme } = useTheme();
  const { companyName } = useCompany();

  const bgColor = useColorModeValue(
    "rgba(39, 39, 42, 0.8)",
    "rgba(39, 39, 42, 0.8)"
  );
  const borderColor = useColorModeValue(
    "rgba(247, 134, 83, 0.5)",
    "rgba(247, 134, 83, 0.5)"
  );

  return (
    <Flex
      width="240px"
      height="50px"
      bg={companyName === "CHEVRON" ? "#0E2863" : bgColor}
      borderRadius="10px"
      border="1px solid"
      borderColor={companyName === "CHEVRON" ? "#081E4F" : borderColor}
      justifyContent="space-between"
      alignItems="center"
      padding="10px"
      position="relative"
      overflow="hidden"
    >
      <Flex
        as="button"
        height="100%"
        width="50%"
        alignItems="center"
        justifyContent="center"
        onClick={() => !isDarkMode && toggleTheme()}
        zIndex="1"
      >
        <FaMoon
          color={isDarkMode ? "rgba(255, 255, 255, 0.6)" : "white"}
          fontSize={16}
        />
        <Text
          color={isDarkMode ? "rgba(255, 255, 255, 0.6)" : "white"}
          fontSize="14px"
          fontWeight="500"
          ml={2}
          pt="30px"
        >
          Dark
        </Text>
      </Flex>
      <Flex
        as="button"
        height="100%"
        width="50%"
        alignItems="center"
        justifyContent="center"
        onClick={() => isDarkMode && toggleTheme()}
        zIndex="1"
      >
        <FaSun
          color={!isDarkMode ? "rgba(255, 255, 255, 0.6)" : "white"}
          fontSize={14}
        />
        <Text
          color={!isDarkMode ? "rgba(255, 255, 255, 0.6)" : "white"}
          fontSize="14px"
          fontWeight="500"
          ml={2}
          pt={7}
        >
          Light
        </Text>
      </Flex>
      <Box
        position="absolute"
        width="45%"
        paddingLeft="10px"
        paddingRight="5px"
        height="calc(100% - 10px)"
        bg={companyName === "CHEVRON" ? "#081E4F" : "black"}
        borderRadius="10px"
        left={isDarkMode ? "120px" : "14px"}
        transition="left 0.3s ease"
      />
    </Flex>
  );
}

export default ThemeToggle;
