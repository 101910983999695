import React from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";

const Loading = () => {
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
      <Spinner size="sm" color="#F78653" />
      <Text color="gray.400" ml={2} pl={5} pt={4}>
        Assistant working...
      </Text>
    </Box>
  );
};

export default Loading;
